import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../context/AuthContext';
import returnToHandler from '../helpers/returnToHandler';

const Layout = ({ children }) => {
  const {
    returnTo,
    setAuthRef
  } = useContext(AuthContext);
  const authRef = useRef(null);

  useEffect(() => {
    setAuthRef(authRef);
  }, [authRef]);

  return (
    <div className="main">
      <div className="auth" ref={authRef}>
        <button
          type="button"
          className="auth__close-btn"
          onClick={() => returnToHandler(returnTo)}
        >
          close
        </button>
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired
};

export default Layout;
